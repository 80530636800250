<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="Item do tipo" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
       
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body pt-4">
                        <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                            <div class="form-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Nome de identificação (uso interno)</label>
                                            <input required type="text" v-model="item.identification_name" class="form-control" :class="{'is-invalid': errors.has('name')}" name="name" v-validate="'required'">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Nome do item</label>
                                            <input type="text" v-model="item.name" class="form-control" :class="{'is-invalid': errors.has('name')}" name="name" v-validate="'required'">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Valor Total</label>
                                            <input type="text" v-model="item.price" class="form-control" :class="{'is-invalid': errors.has('price')}" name="price" v-validate="'required'">
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="col-6 pb-2">
                                            <label>Imagem do<br /> item</label>
                                            <vue-upload-multiple-image
                                                @upload-success="uploadImageSuccess"
                                                @before-remove="beforeRemove"
                                                @edit-image="editImage"
                                                :data-images="item.image"
                                                idUpload="myIdUpload"
                                                editUpload="myIdEdit"
                                                dragText="Clique ou arraste uma image aqui"
                                                browseText="Procurar imagem"
                                                :multiple="false"
                                                :showPrimary="false"
                                                :showEdit="false">
                                            </vue-upload-multiple-image>
                                        </div>   
                                        <div class="col-6 pb-2">
                                            <label>Imagem do ambiente/perspectiva</label>
                                            <vue-upload-multiple-image
                                                @upload-success="uploadImageSuccess3"
                                                @before-remove="beforeRemove3"
                                                @edit-image="editImage3"
                                                :data-images="item.image3"
                                                idUpload="myIdUpload2"
                                                editUpload="myIdEdit2"
                                                dragText="Clique ou arraste uma image aqui"
                                                browseText="Procurar imagem"
                                                :multiple="false"
                                                :showPrimary="false"
                                                :showEdit="false">
                                            </vue-upload-multiple-image>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Cor de identificação</label>
                                            <input type="color" class="form-control" v-model="item.color" name="color" v-validate="'required'" :class="{'is-invalid': errors.has('color')}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions">
                                <div class="text-right">
                                    <button type="submit" class="btn btn-info">{{ id ? 'Salvar' : 'Cadastrar' }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>         
        </div>

    </div>
</div>
</template>
<script>
import KitConfigService from "@/services/resources/KitConfigService";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import {VMoney} from 'v-money'
import Money from "@/mixins/money"

const service = KitConfigService.build();

export default {
  mixins: [Money],
  components: {
      VueUploadMultipleImage
  },
  directives: { money: VMoney },
  data() {
    return {
      item: {
          name: null,
          color: '#000000',
          price: null,
          image: [],
          type: 'PERSONALIZATION_TYPE_ITEM'
      },
      id: "",
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2
      },
      items: [],
      search: '',
      pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            }
       ],
       menu:[
            {
                name: 'Novo cliente',
                to: 'ClientNew'
            }
       ]
    };
  },
  methods: {
    uploadImageSuccess(formData, index, fileList) {
        this.item.image = fileList;
    },
    beforeRemove(index, done, fileList) {
        var r = confirm("Remover imagem?");
        if (r == true) {
            this.item.image.splice(index, 1);
            done();
        }
    },
    editImage(formData, index, fileList) {
        this.item.image = fileList;
    },
    uploadImageSuccess3(formData, index, fileList) {
        this.item.image3 = fileList;
    },
    beforeRemove3(index, done, fileList) {
        var r = confirm("Remover imagem?");
        if (r == true) {
            this.item.image3.splice(index, 1);
            done();
        }
    },
    editImage3(formData, index, fileList) {
        this.item.image3 = fileList;
    },
    toFloat(value){
        return parseFloat(String(value).replace('.','').replace(',', '.'));
    },
    goSearch(){
        if(this.search.length == 0)
            this.fetchitem();

        this.items = this.items.filter(elem => {
            return elem.name.toLowerCase().includes(this.search.toLowerCase());
        });
    },
    edit(index){
        this.id = this.items[index].id;
        this.item = {
            name: this.items[index].name,
            type: "PERSONALIZATION_TYPE_ITEM",
            identification_name: this.items[index].identification_name,
            color: this.items[index].color,
            price: this.items[index].price,
            image: [ {path: this.items[index].image} ],
            image3: [ {path: this.items[index].image3 ?? null} ]
        }
    },
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {
            let item = Object.assign({}, this.item)
            item.image = item.image3 ? item.image[0] : null;
            item.image3 = item.image3 ? item.image3[0] : null;
            item.price = this.toFloat(item.price);
            this.item.type = "PERSONALIZATION_TYPE_ITEM"          
            item.type = this.item.type
            if(this.id){

                item.id = this.id;
                item.url = 'single';
                service
                .update(item)
                .then(resp => {
                    this.$bvToast.toast('Sua configuração foi atualizada com sucesso!', {
                        title: 'Configuração atualizada',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.$validator.reset();
                    this.fetchitem();
                })
                .catch(err => {
                    console.log(err)
                })

            }else{
                service
                .create(item)
                .then(resp => {
                    this.$bvToast.toast('Sua configuração foi criada com sucesso!', {
                        title: 'Configuração criada',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.$validator.reset();
                    this.fetchitem();
                })
                .catch(err => {
                    console.log(err)
                })
            }
        }
      });
    },
    checkField(){
        return this.items.filter(elem => {
            return elem.name.length == 0;
        }).length;
    },
   formatReal( int ){
        var tmp = int+'';
        tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
        if( tmp.length > 6 )
                tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        return tmp;
    },
    update(){

        if(this.checkField()){
            this.$bvToast.toast('Algum campo está inválido, por favor, verifique.', {
                title: 'Campo inválido',
                autoHideDelay: 5000,
                type: 'danger'
            })
            return;
        }

        let data = {
          id: 0,
          config: this.items
        }
    
        service
        .update(data)
        .then(resp => {
            this.$bvToast.toast('Sua configuração foi atualizada com sucesso!', {
                title: 'Configuração atualizada',
                autoHideDelay: 5000,
                type: 'success'
            })
        })
        .catch(err => {
            console.log(err)
        })
    },
    getMoney( str ){
        return parseInt( str.replace(/[\D]+/g,'') );
    },
    fetchitem(){
        if(this.id == ""){
            return;
        }

        let data = {
            id: this.id
        }

        service
          .read(data)
            .then(resp => {
              this.item = resp;
              console.log( this.item.price);
              this.item.price = this.item.price.toLocaleString('pt-br', {minimumFractionDigits: 2});
              this.item.image = [{path: resp.image}]
              this.item.image3 = [{path: resp.image3}]
              this.item.type = "PERSONALIZATION_TYPE_ITEM"
            })
            .catch(err => {
              console.log(err)
            })
    }

  },
  mounted() {
    this.id = this.$route.params.id;
    this.fetchitem();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>